
import { getStoreInfo } from '@/api/storeInfo';
import {
  getLat,
  getLng,
  setLat,
  setLng,
} from '@/utils/cookies';
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { getReservationReviewList } from '@/api/reservationReview';
import { parseDistance } from '@/filters';
import { addRecentList } from '@/utils/localStorage';
import { addViewCount } from '@/api/hits';
import Slick from 'vue-slick';
import { Store } from './store';

@Component({
  components: {
    Slick,
  },
})

export default class extends Vue {
  created() {
    this.initKakao();
    this.getReviewList();
    this.slickLoading();
  }

  mounted() {
    this.getStoreInfo();
  }

  private apiUrl = process.env.VUE_APP_COMMON_API;

  private currentIndex = 0; // 슬라이드현재페이지

  private loading = true;

  private locationVisible = false;

  private map = null;

  private detailQuery: any = {
    lat: '37.566826',
    lng: '126.9786567',
    useDate: moment().format('YYYY-MM-DD'),
  }

  private handleClickAccomodationSlide(str: string) {
    const slick = (this.$refs.accomodationBanner as any);
    if (str === 'next') {
      slick.next();
    } else {
      slick.prev();
    }
  }

  private mainslickOptions = {
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 400,
    // prevArrow: $('.accomodation__prev'),
    // nextArrow: $('.accomodation__next'),
    // draggable: true,
    pauseOnHover: true,
    arrows: false,
    // dots: true,
  };

  private slickLoad = false;

  private isLoading = true;

  private slickLoading() {
    // console.log('시작');
    this.isLoading = true;
    window.setTimeout(() => {
      this.isLoading = false;
      this.slickLoad = true;
      // console.log('종료');
    }, 3000);
  }

  private storeInfo: any = {
    name: '',
    introduce: '',
    tags: [],
    address: '',
    addressDetail: '',
    lat: '',
    lng: '',
    inquiryTel: '',
    basicInfo: '',
    operationTimeInfo: '',
    operationList: '',
    breakTimeStart: '',
    breakTimeEnd: '',
    equipmentInfo: '',
    surroundInfo: '',
    locationInfo: '',
    etcInfo: '',
    parkingInfo: '',
    reserveUrl: '',
    distance: '',
    images: [],
    companyName: '',
    bossName: '',
    companyAddress: '',
    companyAddressDetail: '',
    profileFileUid: '',
    greeting: '',
    reviewCount: 0,
    avgScore: '0.0',
    etc: '',
  }

  private storeSplit = {
    basicInfo: [] as Object[],
    operationTimeInfo: [] as Object[],
    parkingInfo: [] as Object[],
    locationInfo: [] as Object[],
    surroundInfo: [] as Object[],
    etcInfo: [] as Object[],
  }

  private formattedBasicInfo(store: Store) {
    if (store) {
      this.storeSplit.operationTimeInfo = store.operationTimeInfo ? store.operationTimeInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
      this.storeSplit.basicInfo = store.basicInfo ? store.basicInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
      this.storeSplit.parkingInfo = store.parkingInfo ? store.parkingInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
      this.storeSplit.locationInfo = store.locationInfo ? store.locationInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
      this.storeSplit.surroundInfo = store.surroundInfo ? store.surroundInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
      this.storeSplit.etcInfo = store.etcInfo ? store.etcInfo.split('\n').filter(Boolean) : ['입력된 정보가 없습니다'];
    }
  }

  private getStoreInfo() {
    const lat = getLat();
    const lng = getLng();
    if (lat && lng) {
      this.detailQuery.lat = lat;
      this.detailQuery.lng = lng;
      this.getStoreInfoDetail();
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.detailQuery = {
            ...this.detailQuery,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
          this.getStoreInfoDetail();
        }, (error) => {
          this.getStoreInfoDetail();
        },
      );
    }
  }

  private getStoreInfoDetail() {
    this.loading = true;
    getStoreInfo(this.$route.params.idx, this.detailQuery).then((res) => {
      addRecentList(res.data, window.location.pathname);
      this.storeInfo = res.data;
      this.loading = false;
      this.formattedBasicInfo(this.storeInfo);
    }).then(() => {
      addViewCount({ businessIdx: this.$route.params.idx });
    });
  }

  private handleViewLocation() {
    if (this.storeInfo.lat && this.storeInfo.lng) {
      this.locationVisible = !this.locationVisible;
      this.$nextTick(() => {
        this.initMap(this.storeInfo.lat, this.storeInfo.lng);
      });
    } else {
      this.$message.error('지도를 불러오는데 실패했습니다.');
    }
  }

  private initKakao() {
    if (!(window as any).kakao || (window as any).kakao.maps) {
      const script = document.createElement('script');
      script.src = `${window.location.protocol}//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.VUE_APP_KAKAO_CLIENT_ID}&libraries=services`;
      document.head.appendChild(script);
    }
  }

  private initMap(lat: any, lng: any) {
    const container = document.getElementById('map');
    const option = {
      center: new (window as any).kakao.maps.LatLng(lat, lng),
      level: 3,
    };
    this.map = new (window as any).kakao.maps.Map(container, option);
    const markerPosition = new (window as any).kakao.maps.LatLng(lat, lng);
    const marker = new (window as any).kakao.maps.Marker({
      position: markerPosition,
    });
    marker.setMap(this.map);
    const content = `<div class="gis-item">${this.storeInfo.name}</div>`;
    const customOverlay = new (window as any).kakao.maps.CustomOverlay({
      map: null,
      position: markerPosition,
      content,
      yAnchor: 2.4,
      visible: false,
    });
    (window as any).kakao.maps.event.addListener(marker, 'mouseover', () => {
      customOverlay.setMap(this.map);
    });
    (window as any).kakao.maps.event.addListener(marker, 'mouseout', () => {
      customOverlay.setMap(null);
    });
    (window as any).kakao.maps.event.addListener(marker, 'click', () => {
      (window as any).open(`https://map.kakao.com/link/map/${this.storeInfo.name},${lat},${lng}`);
    });
  }

  private handleClickReserve() {
    if (this.storeInfo.reserveUrl) {
      window.open(this.storeInfo.reserveUrl, '_blank');
    } else {
      this.$message.info('예약 준비중입니다.');
    }
  }

  private getReviewList() {
    getReservationReviewList(this.reviewQuery).then((res) => {
      this.reviewList = this.reviewList.concat(res.data.content);
      this.reviewNoMore = res.data.last;
    });
  }

  private handleMoreReviewList() {
    this.reviewQuery.page += 1;
    this.getReviewList();
  }

  private handleCopyTel() {
    this.$copyText(this.storeInfo.inquiryTel).then(() => {
      this.$message.info('복사 완료');
    });
  }

  private informationStatus = '';

  private handleInformationMore() {
    this.informationStatus = '--open';
    this.informationStatus = 'expanded';
  }

  private reviewList = [];

  private reviewQuery = {
    page: 0,
    size: 10,
    stayIdx: this.$route.params.stayIdx,
  }

  private review = {
    propileName: '루피재욱',
    propileAmount: '55',
    propilePhoto: '15',
    rate: 4,
    likeCount: 0,
  }

  private reviewNoMore = false;

  private currentTab = 'menu';

  private com1 = true;

  private com2 = false;

  private tabHandler(e: any) {
    this.com1 = false;
    this.com2 = false;

    if (e.name === 'menu' || e.name === 'reserve' || e.name === 'share') {
      this.com1 = true;
      this.com2 = false;
    } else if (e.name === 'info') {
      this.com2 = true;
      this.com1 = false;
    }
  }

  private isExpanded = false;

  private shortenedIntroduction() {
    return this.storeInfo.introduce && this.storeInfo.introduce.length > 4
      ? `${this.storeInfo.introduce.slice(0, 170)}...`
      : this.storeInfo.introduce;
  }

  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  private reserveOpen() {
    this.$confirm('<div class="reserve__call-img" style="margin:0 0 10px"> <br> 전화예약하시겠습니까?', '', {
      confirmButtonText: '네',
      cancelButtonText: '아니오',
      dangerouslyUseHTMLString: true, // HTML 코드를 사용하기 위해 옵션을 추가합니다.
    }).then(() => {
      // 네를 클릭했을 때의 동작
      window.location.href = `tel:${this.$data.storeInfo.inquiryTel}`;
    }).catch(() => {
      // 아니오나 모달 외의 부분을 클릭했을 때의 동작
      // 여기에 다른 동작을 추가하거나 생략해도 됩니다.
    });
  }

  private likeStatus = false;

  private handleClickLike() {
    this.likeStatus = !this.likeStatus;
    if (this.likeStatus) {
      this.review.likeCount += 1;
      return true;
    }
    this.review.likeCount -= 1;
    return false;
  }

  private getPrivateData() {
    const privateData = this.review.likeCount;
    return privateData;
  }

  private ShowButton() {
    let str = this.$data.storeInfo.introduce;
    if (str === null) str = '';
    const introduction = str;
    const introductionLength = introduction.length;
    const windowWidth = this.getWindowWidth();

    if (windowWidth >= 1024) {
      return this.$data.informationStatus !== 'expanded' && introductionLength >= 450;
    }
    return this.$data.informationStatus !== 'expanded' && introductionLength >= 150;
  }

  private getWindowWidth(): number {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  }

  private ShowArrowButton(): boolean {
    const tagsLength = this.$data.storeInfo.tags.length;
    return this.getWindowWidth() >= 1024 ? tagsLength > 10 : tagsLength > 4;
  }

  // 슬라이드 현재페이지 숫자
  private carouselChange(index: number): void {
    if (this.storeInfo.images.length <= index) {
      this.currentIndex -= 1;
    } else {
      this.currentIndex = index;
    }
  }

  private handleKakaoShare() {
    const baseUrl = process.env.VUE_APP_TARGET;
    const shareUrl = window.location.href;
    (window as any).Kakao.cleanup();
    (window as any).Kakao.init(process.env.VUE_APP_KAKAO_APP_KEY);
    (window as any).Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: this.storeInfo.name,
        description: this.storeInfo.introduce,
        imageUrl: this.storeInfo.images.length > 0 ? `${baseUrl}/api/attached-file/${this.storeInfo.images[0]}` : `${baseUrl}/favicon.ico`,
        link: {
          mobileWebUrl: shareUrl,
          webUrl: shareUrl,
        },
      },
      social: {
        likeCount: 0,
        commentCount: 0,
        sharedCount: 0,
      },
      buttons: [
        {
          title: '보러가기',
          link: {
            mobileWebUrl: shareUrl,
            webUrl: shareUrl,
          },
        },
      ],
    });
  }

  private curTabs: number[] = [1, 2];

  private toggleSection(section: number): void {
    const index = this.curTabs.indexOf(section);
    if (index !== -1) {
      this.curTabs.splice(index, 1);
    } else {
      this.curTabs.push(section);
    }

    for (let i = 1; i <= 4; i += 1) {
      const icon = this.$refs[`icon${i}`] as HTMLElement;
      const isOpen = this.curTabs.includes(i);
      if (isOpen) {
        icon.classList.remove('el-icon-arrow-down');
        icon.classList.add('el-icon-arrow-up');
      } else {
        icon.classList.remove('el-icon-arrow-up');
        icon.classList.add('el-icon-arrow-down');
      }
    }
  }

  private isSectionVisible(section: number): boolean {
    return this.curTabs.includes(section);
  }
}
